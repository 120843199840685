<template>
  <b-row>
    <b-col cols="12">
      <p
        v-if="items.length == 0 && searchTerm && gettingData == false"
        class="item-count float-right d-none d-lg-block"
      >
        Geen resultaten gevonden
      </p>
    </b-col>
    <div
      v-for="item in items"
      :key="item.ID"
      class="col-xl-3 col-lg-4 col-md-6 col-xs-12 mb-4"
    >
      <Item :item="item" />
    </div>
  </b-row>
</template>

<script>
// @ is an alias to /src

import { getItems, getPackages } from '@/services/ItemService'
import Item from '@/components/items/Item'

export default {
  name: 'Items',
  components: {
    Item
  },
  props: {
    categoryid: {
      type: Number,
      required: false,
      default: function() {
        return
      }
    },
    searchTerm: {
      type: String,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      items: [],
      index: 1,
      size: 500,
      total: 1,
      gettingData: false,
      searchValue: '',
      categoryID: null
    }
  },
  computed: {
    pages: function() {
      return Math.ceil(this.total / this.size)
    }
  },
  watch: {
    index: async function(val) {
      if (this.gettingData) {
        return
      }
      this.gettingData = true

      await this.updateItemData({ index: val })

      this.gettingData = false
    },
    searchValue: async function(val) {
      if (this.gettingData) {
        return
      }
      this.gettingData = true

      await this.updateItemData({ search: val })

      this.gettingData = false
    }
  },
  async mounted() {
    this.categoryID = this.$route.params.categoryid
    this.updateItemData()
  },
  methods: {
    async updateItemData({
      size = this.size,
      index = this.index,
      search = this.searchTerm,
      categoryID = this.categoryid
    } = {}) {
      this.gettingData = true
      let items
      if (this.$route.name == 'packages') {
        items = await getPackages()
        this.items = items
        this.total = items.data.Total
        this.$emit('totals', this.total)
      } else {
        items = await getItems({
          size,
          index,
          search,
          categoryID,
          AllItemData: false
        })
        this.items = items.data.Collection
        this.currentPage = items.data.Index
        this.size = items.data.Size
        this.total = items.data.Total
        this.$emit('totals', this.total)
      }
      this.gettingData = false
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>
